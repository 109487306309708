import React from 'react';
import ReactDOM from 'react-dom';

import { SentryClient } from "msg-sentry-client-web";

import './App.less';
import { BusySpinner } from "msg-ui-react/dist/components/busy-spinner/BusySpinner"

SentryClient.start((user?: Oidc.User) => {
    try {
        startApp();
    } catch (e) {
        console.error("Unable to get client settings from server, try using development settings");
        console.log(e);
    }
})
.catch(e => {
    console.error("Unable to get client settings from server, try using development settings");
    console.log(e);
});

const startApp = async function () {

    ReactDOM.render(
        <React.StrictMode>
            <h1>Place holder</h1>
            <p>
                This is a placeholder for the new supervisor UI.
            </p>
            <div>
                <BusySpinner message="Hello World!"/>
            </div>
        </React.StrictMode>,
        document.getElementById('root')
    );
}